.img-cover img {
  width: 100%;
  height: 3.5rem;
  display: block;
  object-fit: cover;
}

.goods-content {
  width: 100%;
  background-color: #ffffff;
}

.goods-content .title {
  width: 100%;
  padding: 0.1rem;
  box-sizing: border-box;
}

.goods-content .title .label {
  border: 0.01rem solid #FF6934;
  font-size: 0.12rem;
  padding: 0 0.04rem;
  margin-right: 0.05rem;
  color: #FF6934;
  margin-left: 0.05rem;
}

.goods-content .title .name {
  font-size: 0.16rem;
}

.goods-content .money {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.1rem;
  box-sizing: border-box;
}

.goods-content .money .span {
  font-size: 0.16rem;
  color: #FF6934;
}

.goods-content .money .piece {
  font-size: 0.11rem;
  color: #999999;
}

.goods-content .money .box {
  display: flex;
  align-items: center;
}

.goods-content .money .crossed_price {
  position: relative;
  margin-left: 0.1rem;
}

.goods-content .money .crossed_price span {
  font-size: 0.16rem;
  color: #999999;
}

.goods-content .money .crossed_price .line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.01rem solid #999999;
  width: 100%;
  color: #999999;
}

.goods-content .money .share {
  display: flex;
  align-items: center;
}

.goods-content .money .share img {
  width: 0.17rem;
  height: 0.17rem;
  margin-right: 0.03rem;
}

.goods-content .money .share span {
  font-size: 0.16rem;
}

.goods-content .spec {
  width: 100%;
  padding: 0.1rem 0.1rem 0 0.1rem;
  box-sizing: border-box;
}

.goods-content .spec .value {
  font-size: 0.16rem;
}

.goods-content .spec .color-ccc {
  color: #CCCCCC;
}

.goods-content .details {
  width: 100%;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  box-sizing: border-box;
}

.goods-content .details .details-title {
  font-size: 0.22rem;
  margin-bottom: 0.1rem;
}

.goods-content .details .item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.03rem;
}

.goods-content .details .item .label {
  color: #666666;
  font-size: 0.16rem;
  margin-right: 0.03rem;
}

.goods-content .details .item .value {
  color: #666666;
  font-size: 0.16rem;
}

.gap {
  height: 0.6rem;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -0.01rem 0.06rem #dddddd;
}

.footer .box {
  width: 100%;
  height: 0.49rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0.15rem;
  box-sizing: border-box;
}

.footer .box .right {
  margin: 0 auto;
  width: 90%;
  height: 0.37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #00A870 0%, #00A870 100%);
  border-radius: 0.25rem;
}

.footer .box .right span {
  font-size: 0.17rem;
  color: #ffffff;
}

.popup-box {
  width: 100%;
  height: 4rem;
  position: relative;
}

.popup-box .top {
  padding: 0.15rem;
  box-sizing: border-box;
}

.popup-box .top .money {
  display: flex;
  align-items: center;
}

.popup-box .top .money .span {
  font-size: 0.16rem;
  color: #FF6934;
}

.popup-box .top .money .piece {
  font-size: 0.11rem;
  color: #999999;
}

.popup-box .top .money .crossed_price {
  position: relative;
  margin-left: 0.1rem;
}

.popup-box .top .money .crossed_price span {
  font-size: 0.16rem;
  color: #999999;
}

.popup-box .top .money .crossed_price .line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.01rem solid #999999;
  width: 100%;
  color: #999999;
}

.popup-box .top .name {
  font-size: 0.17rem;
  margin-top: 0.08rem;
}

.popup-box .scroll {
  width: 100%;
  padding: 0.15rem 0.15rem 0 0.15rem;
  box-sizing: border-box;
  height: 1.8rem;
}

.popup-box .scroll .title {
  font-size: 0.17rem;
}

.popup-box .scroll .spec {
  width: 100%;
  margin-top: 0.1rem;
}

.popup-box .scroll .spec .spec-button {
  border-radius: 0.05rem;
  padding: 0.04rem 0.15rem;
  border: 0.01rem solid #DDDDDD;
  display: inline-block;
  margin-right: 0.08rem;
  margin-bottom: 0.08rem;
}

.popup-box .scroll .spec .spec-button span {
  font-size: 0.17rem;
}

.popup-box .scroll .spec .spec-button-fff {
  background-color: #F6F7F8;
  color: #D4DFF8;
}

.popup-box .scroll .spec .spec-button-yes {
  border: 0.01rem solid #FF6934;
  color: #FF6934;
}

.popup-box .inventory {
  padding: 0.15rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-box .inventory .left {
  font-size: 0.17rem;
}

.popup-box .inventory .right {
  font-size: 0.17rem;
  color: #999999;
  margin-left: 0.05rem;
}

.popup-box .goods-buy {
  padding: 0.15rem;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.popup-box .goods-buy .buy-button {
  width: 100%;
  height: 0.44rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #00A870 0%, #00A870 100%);
  border-radius: 0.08rem;
}

.popup-box .goods-buy .buy-button span {
  font-size: 0.19rem;
  color: #ffffff;
}